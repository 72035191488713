<template>
  <div>
  <kendo-datasource
      :ref="'publicationDataSource'"
      :data="publicationDataSource.data"
      :page-size="'8'">
    </kendo-datasource>
    <kendo-grid
      :ref="'importGrid1'"
      :data-source-ref="'publicationDataSource'"
      style="width: calc(100vw - 20px);"
      :editable="editable"
      :sortable="sortable"
      :scrollable="scrollable"
      :toolbar="toolbar"
      :resizable="resizable"
      :filterable="filterable"
      :columns="columns"
      :messages="messages">
    </kendo-grid>
  </div>
</template>

<script>
import PublicationDataSource from '@/assets/data/Publication.json'

export default {
  name: 'import-manual',
  data () {
    return {
      publicationDataSource: PublicationDataSource,
      toolbar: ['create'],
      commandEdit: 'edit',
      commandDelete: 'destroy',
      columns: [
        {
          title: 'Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'PublicationIdentity', title: 'Publication ID', width: '120px', headerAttributes: { class: 'grid-header-de1' }, editable: false },
            { field: 'Author', title: 'Author', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', template: 'Randomized comparison of cis-diammined...', width: '300px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Abstract', title: 'Abstract', template: 'Fifty-seven patients with recurrent squa...', width: '400px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'TypeOfPublication', title: 'Type of Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceName', title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceDate', title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportName', title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'OrganizingBody', title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportDate', title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', template: 'http://dx.doi.org/10.1016/...', width: '200px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        {
          title: 'Other Publication Fields',
          headerAttributes: { class: 'grid-header-de2' },
          columns: [
            { field: 'Duplicate', title: 'Duplicate', width: '200px', headerAttributes: { class: 'grid-header-de2' } },
            { field: 'CurrentStage', title: 'Stage', width: '200px', headerAttributes: { class: 'grid-header-de2' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-de2' } },
            { field: 'FullText', title: 'Full Text Link (s)', width: '200px', headerAttributes: { class: 'grid-header-de2' } }
          ]
        },
        { command: 'edit', width: '70px' },
        { command: 'destroy', width: '70px' }
      ],
      messages: {
        commands: {
          cancel: '',
          canceledit: 'Cancel changes',
          create: 'Add new publication',
          destroy: 'Delete',
          edit: 'Edit',
          update: 'Save changes'
        }
      },
      editable: 'popup',
      resizable: true,
      filterable: true,
      sortable: true,
      scrollable: true
    }
  }
}
</script>
